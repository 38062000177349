<template>
  <div>

    <!-- MAIN SLIDER-->
    <v-sheet class="d-flex justify-center" :color="wsBACKGROUND" >
      <v-sheet :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  class="pt-8" color="transparent" width="1200">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="6">
            <div class="fill-height d-flex align-center">
              <div>
                <v-img v-if="displayShow(['sm'])" class="wsRounded" aspect-ratio="1.1" src="@/assets/img/login_img.png" transition="xxx" />
                <h3 :class="displayShow(['sm']) ? 'text-center' : '' "
                    :style="`color: ${wsDARK}`" class="font-weight-medium mt-6 ">Сучасні рішення для управління університетом та ведення навчального процесу </h3>
                <h5 :class="displayShow(['sm']) ? 'text-center' : '' " class=" font-weight-medium mt-3"><strong>ТОВ «WeStudy»</strong>  є вітчизняною IT-компанію, яка займається цифровізацією навчальних закладів України, та має конкретні рішення під систему освіти України. Організація та ведення навчального процесу онлайн, наскрізна інтеграція навчальних та робочих планів, електронна залікова книжка, уніфікований розклад занять для студентів і викладачів та багато інших рішень – розроблялися та вивірялися роками із постійним консультуванням професорсько-викладацького складу провідних українських університетів.</h5>

              </div>
            </div>
          </v-col>
          <v-col v-if="displayHide(['sm'])" cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="pl-16" >
            <div class="d-flex justify-center align-center fill-height">
              <v-img transition="xxx" class="wsRounded" aspect-ratio="1.1" src="@/assets/img/login_img.png" />
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>


    <v-sheet class="d-flex justify-center" color="white" >
      <v-sheet   :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8" class="pa-0 mt-0">
            <div class="pr-6" :class="displayHide(['sm']) ? 'pr-6' : 'pa-6'" >
              <h1 class="mt-3" :class="displayShow(['sm']) ? 'text-center' : '' " :style="`color: ${wsDARK}`" style="line-height: 1.2">Про Нас </h1>
              <h3 :class="displayShow(['sm']) ? 'text-center' : '' "  class=" mb-8 font-weight-medium ">WeStudy</h3>

              <p>Серед партнерських університетів, які консультували нашу команду та зараз користуються платформою<strong>WeStudy™</strong>можна виділити:</p>
              <ul style="list-style-type: square;">
                <li>Національний університет «Львівська Політехніка» </li>
                <li>Волинський національний університет імені Лесі Українки </li>
                <li>Університет Григорія Сковороди у Переяславі </li>
                <li>Миколаївський національний університет імені В.О. Сухомлинського </li>
                <li>Уманський державний педагогічний університет імені Павла Тичини </li>
                <li>та інші.</li>
              </ul>
              <p class="mt-4">Проект <strong>WeStudy™</strong> бере початок із 2017 року, в якому було започатковано платформу супроводження міжнародних навчальних програм подвійних дипломів між університетами України та іноземних партнерів. За цей час проект виділився в окрему IT-компанію, яка зарекомендувала себе надійним партнером в сфері цифровізації процесів вищої освіти серед багатьох провідних університетів України та іноземних закладів освіти.</p>

              <p>Розробниками платформи є команда вчених, менеджерів та програмістів які багато років працювали в університетах на адміністративних посадах, мають великий досвід викладання. Ми добре знаємо специфіку роботи університетів з усіх боків та знаємо що необхідно для полегшення роботи як викладацького складу так і адміністрації. Окрему увагу ми приділяємо комфорту користування платформою безпосередньо студентом, адже саме студенти є кінцевими споживачами навчальних послуг університету.</p>
              <p><strong>WeStudy™</strong> - унікальна інтегрована онлайн платформа для закладів освіти. Сучасні рішення для ведення навчального процесу як класичного, так і дистанційного, просування послуг закладу освіти на світовому ринку освітніх та наукових послуг. Онлайн навчання, електронна залікова книжка із інтеграцією з деканатом, динамічний розклад для викладачів та студентів, система бухгалтерії із функцією сплати за навчання через мобільний додаток, ефективний документообіг, система набору абітурієнтів на території України та світу, розгорнутий блок оповіщень користувачів та багато інших найновітніших рішень - це <strong>WeStudy™</strong>.</p>

              <p>Ми постійно вдосконалюємо нашу платформу у відповідності до побажань замовників;</p>
              <h3 class="text-center font-weight-light mt-9">Свідоцтво на торговельну марку України <strong>№ 310596</strong></h3>
              <h3 class="text-center font-weight-light mb-6">Ідентифікаційний код юридичної особи <strong>№ 44338526</strong></h3>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="pa-0 mt-0">
            <div class="pl-6">
              <h3 class="mb-6 text-center montserat">Документи</h3>
              <a style="text-decoration: none; color: white" href="https://sis.ukrpatent.org/uk/search/detail/1590985/">

                <img class="wsRoundedHalf" src="https://api.westudy.ua/images/documentation/blob(1).jpg" alt="WeStudy™ - унікальна інтегрована онлайн платформа для закладів освіти. Свідоцтво на торговельну марку України № 310596" width="100%">
                <h5 class="text-center font-weight-light mt-4">Свідоцтво про торгівельну марку</h5>
                <v-sheet light class="mt-6 pa-6 wsRoundedHalf">
                  <img  src="https://api.westudy.ua/images/documentation/blob(8).png" alt="WeStudy™ - унікальна інтегрована онлайн платформа для закладів освіти. Свідоцтво на торговельну марку України № 310596" width="100%">
                </v-sheet>
                <h5 class="text-center font-weight-light mt-4">Відомості про торгівельну марку</h5>
              </a>
            </div>
          </v-col>
        </v-row>

      </v-sheet>
    </v-sheet>

  </div>
</template>

<script>
export default {
  name: "HomeAbout"
}
</script>

<style scoped>

</style>